@tailwind base;
@tailwind components;
@tailwind utilities;

body.light {
  background-color: #f7f8fd;
  color: #333333;
}

body.dark {
  background-color: #242526;
  color: #c1c2c9;
}

.default-style li{
  list-style: inside;
}

.default-style.decimal li{
  list-style: decimal;
}

.default-style h1{
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 0.5rem;
}

.radialBar .recharts-radial-bar-background-sector {
  fill: #515354;
}