.react-simplemde-editor-dark .CodeMirror {
  background: #333333;
  color: #fff;
  border-color: #777777;
}

.react-simplemde-editor-dark .editor-toolbar {
  border-color: #777777;
}

.react-simplemde-editor-dark .editor-toolbar button:hover {
  background: #515354;
}
